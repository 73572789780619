const SAVE_IMAGE_DND = 'activities/save-image-dnd';
const SAVE_TEXT_DND = 'activities/save-text-dnd';
const SAVE_DRAW = 'activities/save-draw';
const SAVE_IMAGE_UPLOAD = 'activities/save-image-upload';
const SAVE_ANSWERS = 'activities/save-text-fill';
const SAVE_TEXT_WITH_IMAGES = 'activities/save-text-with-images';
const SAVE_CLASSIFY_IMAGES = 'activities/save-classify-images';
const SAVE_DND_VERTICAL_IMAGES = 'activities/save-dnd-vertical-images';
const SAVE_DRAW_WITH_TEXT = 'activities/save-draw-with-text';
const SAVE_IMAGE_UPLOAD_WITH_TEXT = 'activities/save-image-upload-with-text';
const SAVE_IMAGE_DND_WITH_TEXT = 'activities/save-image-dnd-with-text';
const SAVE_TEXT_DND_WITH_DRAW_AND_TEXT =
	'activities/save-text-dnd-with-draw-and-text';
const GET_ACTIVITES = 'activities/get-activities';
const SHOW_MODAL = 'activities/modal';
const DISPLAY_ACTIVITIES = 'activities/display-activities';
const FINISH_ACTIVITY = 'activities/finish-activities';
const ERROR = 'activities/error';

export {
	SAVE_IMAGE_DND,
	SAVE_TEXT_DND,
	SAVE_IMAGE_UPLOAD,
	SAVE_DRAW,
	SAVE_ANSWERS,
	SAVE_TEXT_WITH_IMAGES,
	SAVE_CLASSIFY_IMAGES,
	SAVE_DND_VERTICAL_IMAGES,
	SAVE_DRAW_WITH_TEXT,
	SAVE_TEXT_DND_WITH_DRAW_AND_TEXT,
	SAVE_IMAGE_DND_WITH_TEXT,
	SAVE_IMAGE_UPLOAD_WITH_TEXT,
	FINISH_ACTIVITY,
	GET_ACTIVITES,
	SHOW_MODAL,
	DISPLAY_ACTIVITIES,
	ERROR,
};
