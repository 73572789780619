import * as constants from './constants';
import { db } from '../../../config/firebase';

const users = db.collection(`/users`);

const userCRUD = (values, callback) => {
	users
		.doc(values.userId)
		.get()
		.then((doc) => {
			if (doc.exists) {
				users
					.doc(values.userId)
					.set(
						{
							activities: {
								[values.activity + '-' + values.slug]: values,
							},
						},
						{ merge: true }
					)
					.then(callback);
			} else {
				// doc.data() will be undefined in this case
				users
					.doc(values.userId)
					.set({
						activities: {
							[values.activity + '-' + values.slug]: values,
						},
					})
					.then(callback);
			}
		});
};

export const saveTextInputWithImages = (values) => async (dispatch) => {
	userCRUD(
		values,
		dispatch({ type: constants.SAVE_TEXT_WITH_IMAGES, payload: values })
	);
};
export const saveClassifyImages = (values) => async (dispatch) => {
	userCRUD(
		values,
		dispatch({ type: constants.SAVE_CLASSIFY_IMAGES, payload: values })
	);
};
export const saveImageDnd = (values) => async (dispatch) => {
	userCRUD(
		values,
		dispatch({ type: constants.SAVE_IMAGE_DND, payload: values })
	);
};
export const saveTextDnd = (values) => async (dispatch) => {
	userCRUD(
		values,
		dispatch({ type: constants.SAVE_TEXT_DND, payload: values })
	);
};
export const saveDraw = (values) => async (dispatch) => {
	userCRUD(values, dispatch({ type: constants.SAVE_DRAW, payload: values }));
};
export const saveImageUpload = (values) => async (dispatch) => {
	userCRUD(
		values,
		dispatch({ type: constants.SAVE_IMAGE_UPLOAD, payload: values })
	);
};

export const saveAnswers = (values) => async (dispatch) => {
	userCRUD(values, dispatch({ type: constants.SAVE_ANSWERS, payload: values }));
};
export const saveDndVerticalImages = (values) => async (dispatch) => {
	userCRUD(
		values,
		dispatch({ type: constants.SAVE_DND_VERTICAL_IMAGES, payload: values })
	);
};
export const saveDrawWithText = (values) => async (dispatch) => {
	userCRUD(
		values,
		dispatch({ type: constants.SAVE_DRAW_WITH_TEXT, payload: values })
	);
};
export const saveTextDndWithDrawAndText = (values) => async (dispatch) => {
	userCRUD(
		values,
		dispatch({
			type: constants.SAVE_TEXT_DND_WITH_DRAW_AND_TEXT,
			payload: values,
		})
	);
};
export const saveImageDndWithText = (values) => async (dispatch) => {
	userCRUD(
		values,
		dispatch({
			type: constants.SAVE_IMAGE_DND_WITH_TEXT,
			payload: values,
		})
	);
};
export const saveImageUploadWithText = (values) => async (dispatch) => {
	userCRUD(
		values,
		dispatch({
			type: constants.SAVE_IMAGE_UPLOAD_WITH_TEXT,
			payload: values,
		})
	);
};
export const getActivities = () => async (dispatch) => {
	db.collection('lectures')
		.orderBy('id')
		.get()
		.then((querySnapshot) => {
			const data = querySnapshot.docs.map((doc) => doc.data());
			dispatch({ type: constants.GET_ACTIVITES, payload: data });
		});
};
export const setModal = (values) => async (dispatch) => {
	dispatch({ type: constants.SHOW_MODAL, payload: values });
	setTimeout(() => {
		dispatch({ type: constants.SHOW_MODAL, payload: {} });
	}, 3500);
};
export const setDisplayActivities = (values) => async (dispatch) => {
	dispatch({ type: constants.DISPLAY_ACTIVITIES, payload: values });
	// setTimeout(() => {
	// 	dispatch({ type: constants.SHOW_MODAL, payload: {} });
	// }, 3500);
};
export const finishActivity = (values) => async (dispatch) => {
	console.log(values);
	users
		.doc(values.userId)
		.get()
		.then((doc) => {
			if (doc.exists) {
				users
					.doc(values.userId)
					.set(
						{
							activitiesFinished: {
								[values.activity]: values.activity,
							},
						},
						{ merge: true }
					)
					.then(dispatch({ type: constants.FINISH_ACTIVITY, payload: values }));
			} else {
				// doc.data() will be undefined in this case
				users
					.doc(values.userId)
					.set({
						activitiesFinished: {
							[values.activity]: values.activity,
						},
					})
					.then(dispatch({ type: constants.FINISH_ACTIVITY, payload: values }));
			}
		});
};
